import React, { useState } from 'react'
import Layout from '../components/Layout.js'
import { Icon, Gutter, Row, Button, Form, Loader } from '@patomation/react-ui-components'
import { Link, navigate } from 'gatsby'
import useGlobal from '../hooks/useGlobal'
import CheckoutTabs from '../components/CheckoutTabs'
import useMutation from '../hooks/useMutation'

const Checkout = () => {

  const [ globalState, setGlobalState ] = useGlobal({
    cart:[],
    customer: {
      firstName: null,
      lastName: null,
      email: null,
      email2: null,
      phone: null,
      country: null,
      hotelDetails: null,
      extraComments: null
    },
    bookingId: null
  })

  const ADD_BOOKING = `
    mutation {
      addBooking (
        input: {
          firstName: "$firstName"
          lastName: "$lastName"
          email: "$email"
          phone: "$phone"
          country: "$country"
          hotelDetails: "$hotelDetails"
          extraComments: "$extraComments"
          days: $days
        }) {
          _id
          firstName
          lastName
      }
    }
  `
  const UPDATE_BOOKING = `
    mutation {
      updateBooking (
        id: "$id",
        input: {
          firstName: "$firstName"
          lastName: "$lastName"
          email: "$email"
          phone: "$phone"
          country: "$country"
          hotelDetails: "$hotelDetails"
          extraComments: "$extraComments"
          days: $days
        }) {
          _id
          firstName
          lastName
      }
    }
  `
  const [complete, setComplete] = useState(false)
  const [submitBooking, { loading }] = useMutation(
    // ADD_BOOKING, {
    (globalState.bookingId !== null ? UPDATE_BOOKING : ADD_BOOKING), {
      onCompleted: ({_id}) => {
        setGlobalState({bookingId: _id})
        setComplete(true)
      }
    })

  if(loading) return (
    <Layout>
      <Loader
        message='Submitting Please Wait'
        progressBar={true}
        progressBarColor='#86AC41'
        progressBarTime={10000}
        complete={complete}
        spinner={false}
        onComplete={() => {
          navigate('/payment')
        }}/>
    </Layout>
  )

  return (
   <Layout aboveMain={<CheckoutTabs active='Info'/>}>

    <section>
    <Form
      className='checkout-form'
      inputStyle={{
        textAlign: 'left'
      }}
      labels={true}
      placeholders={true}
      fields={[
        {
          name:'firstName',
          required: true,
          value: globalState.customer.firstName
        },
        {
          name:'lastName',
          required: true,
          value: globalState.customer.lastName
        },
        {
          name:'email',
          type: 'email',
          label: 'Email',
          placeholder: 'Enter email address',
          required: true,
          validation: 'email',
          value: globalState.customer.email
        },
        {
          name:'email2',
          type: 'email',
          label: 'Email',
          placeholder: 'Verify email address',
          required: true,
          validation: 'matches-email',
          value: globalState.customer.email2
        },
        {
          name:'phone',
          type: 'phone',
          validation: 'phone',
          required: true,
          value: globalState.customer.phone
        },
        {
          name:'country',
          required: true,
          value: globalState.customer.country
        },
        { name:'hotelDetails',
          label: 'Hotel Details',
          type: 'textarea',
          placeholder: 'Tell us about your hotel details',
          required: true,
          value: globalState.customer.hotelDetails
        },
        { name:'extraComments',
          label: 'Extra Comments',
          type: 'textarea',
          placeholder: 'Any additional comments or concerns',
          required: true,
          value: globalState.customer.extraComments
        }
      ]}
      onSubmit={ values => {
        setGlobalState({customer: values}) //backup values
        submitBooking({variables: {
          ...values,
          days: JSON.stringify(globalState.cart).replace(/\"([^(\")"]+)\":/g,"$1:"),  /* eslint-disable-line */
          id: globalState.bookingId
        }})
      }}
      submitButton={
        <Row>

          <Link to='/cart'>
            <Button color='#86AC41' kind='outline'>
              <Icon name='keyboard_backspace' color='#86AC41'/><Gutter vertical />
              Back to cart
            </Button>
          </Link>

          <Gutter vertical />

          <Button background='#86AC41' color='#324851'
            style={{marginLeft: 'auto'}}
            type='submit'>
            Next Step
          </Button>

        </Row>
      }/>



    </section>
   </Layout>
 )

}

export default Checkout
